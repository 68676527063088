<template>
  <section id="dashboard">
    <b-overlay
      variant="transparent"
      :show="show"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <div v-if="load">
        <component
          :is="route"
          :key="'dashboard'"
        />
      </div>
    </b-overlay>
  </section>
</template>

<script>
import * as constants from '@core/utils/constants'

import {
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { findPermissionsByName, messageError, showToast } from '@/store/functions'
import store from '@/store'
import { getUserData } from '@/auth/utils'

const DashboardSubgroup = () => import('./DashboardRoles/DashboardSubgroup.vue')
const DashboardSuper = () => import('./DashboardRoles/DashboardSuper.vue')
const DashboardEditor = () => import('./DashboardRoles/DashboardEditor.vue')
const DashboardSponsor = () => import('./DashboardRoles/DashboardSponsor.vue')
const DashboardVendor = () => import('./DashboardRoles/DashboardVendor.vue')
const DashboardGestor = () => import('./DashboardRoles/DashboardGestor.vue')
const DashboardPubli = () => import('./DashboardRoles/DashboardPubli.vue')
const DashboardDessign = () => import('./DashboardRoles/DashboardDessign.vue')
const DashboardStatistics = () => import('./DashboardRoles/DashboardStatistics.vue')
const DashboardPublicador = () => import('./DashboardRoles/DashboardPublicador.vue')
const DashboardNotifications = () => import('./DashboardRoles/DashboardNotifications.vue')
const analytics = () => import('../metrics-content/Analytics.vue')

export default {
  components: {
    DashboardPublicador,
    DashboardDessign,
    DashboardVendor,
    DashboardEditor,
    DashboardSponsor,
    DashboardPubli,
    DashboardSuper,
    DashboardSubgroup,
    analytics,
    DashboardGestor,
    DashboardStatistics,
    DashboardNotifications,
    BOverlay,
  },
  data() {
    return {
      roles: null,
      data: {},
      show: false,
      load: false,
      rol: constants,
      route: '',
    }
  },
  created() {
    this.show = true
    const userData = getUserData()
    const query = `{
      client(id: "${userData.profile.client.id}") {
        id
        name
        totalVideos
        totalProducts
        totalInteractivity
        contentInteractivityLimit
        initialCatLimit
        nestedCatLimit
        initialCatProductLimit
        nestedCatProductLimit
        productsCatLimit
      }
    }`
    axios.post('', { query }).then(response => {
      messageError(response, this)

      const data = response.data.data.client
      const platformConfig = {
        initialCategoryLimit: data.initialCatLimit,
        nestedCategoryLimit: data.nestedCatLimit,
        totalContentsLimit: data.totalVideos,
        totalProductsLimit: data.totalProducts,
        totalInteractivityLimit: data.totalInteractivity,
        contentInteractivityLimit: data.contentInteractivityLimit,
      }
      store.commit('app/SET_PLATFORM_CONFIGURATION', platformConfig)
    }).catch(err => {
      // eslint-disable-next-line
      console.log(err)
      showToast(this.$t('serverError'), 2, this)
    })

    /* ROLES */
    const queryUsers = `query{
          allCustomUsers(id:"${userData.id}") {
            edges {
              node {
                id
                username
                groups {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          allProfiles(id: "${userData.id}") {
            edges {
              node {
                id
                username
                subgroup {
                  name
                  extraData
                }
              }
            }
          }
        }`
    axios
      .post('', {
        query: queryUsers,
      })
      .then(result => {
        messageError(result, this)
        this.show = false
        this.load = true

        this.roles = result.data.data.allCustomUsers.edges[0].node.groups.edges
        const superUser = userData.isSuperuser
        let subgroup = null
        if (!superUser) {
          subgroup = result.data.data.allProfiles.edges[0].node.subgroup
        }
        let extraData = false
        let hasSubgroups = false
        hasSubgroups = userData?.profile?.client?.hasSubgroups
        if (subgroup && hasSubgroups) {
          if (JSON.parse(subgroup.extraData)?.download_resources) {
            extraData = JSON.parse(subgroup.extraData).download_resources
          } else {
            extraData = false
          }
        }

        const roleMap = {
          'dashboard-super': this.rol.administrador,
          'dashboard-editor': this.rol.editorCont,
          'dashboard-statistics': this.rol.gestorStatis,
          'dashboard-notifications': this.rol.gestorNotifications,
          'dashboard-publi': this.rol.publi,
          'dashboard-gestor': this.rol.gestorUser,
          'dashboard-publicador': this.rol.publicadorCont,
          'dashboard-sponsor': this.rol.sponsor,
          'dashboard-vendor': this.rol.vendor,
        }

        // Función para verificar si un rol específico existe en `groupsEdges`
        function hasRole(groupsEdges, roleName) {
          return groupsEdges.some(edge => edge.node?.name === roleName)
        }

        // Buscar el primer dashboard donde el rol está presente en `this.roles`
        const matchedDashboard = Object.entries(roleMap).find(
          ([, role]) => hasRole(this.roles, role),
        )?.[0] || 'dashboard-super'
        const matchingPermissions = findPermissionsByName('menu_cms')
        if (matchingPermissions.length === 1 && matchingPermissions.includes('users.menu_cms_analytics')) {
          this.route = 'analytics'
        } else if (extraData) {
          this.route = 'dashboard-subgroup'
        } else {
          this.route = matchedDashboard
        }
      })
      .catch(err => {
        console.log(err)
        this.roles = null
      })
  },

}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
